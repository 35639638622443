import tickets from '../public/assets/imgs/tickets.png';
import cinemaManagement3 from '../public/assets/imgs/cinema-management-3.png';
import cinemaManagement4 from '../public/assets/imgs/cinema-management-4.png';
import popcorn from '../public/assets/imgs/popcorn-6.png';
import pos2 from '../public/assets/imgs/pos-2.png';
import saveMoney from '../public/assets/imgs/save-money-2.png';

export default {
    images: {
        tickets,
        cinemaManagement3,
        cinemaManagement4,
        popcorn,
        pos2,
        saveMoney,
    },
};
