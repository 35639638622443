import axios from 'axios';

class SanityClient {
    sanityBaseUrl: string;
    promises: { [key: string]: Promise<any> };
    constructor() {
        this.promises = {};
        this.sanityBaseUrl = 'https://xmy38fqv.apicdn.sanity.io/v2021-10-21/data/query/production?query=';
    }
    get = (ref: string) => {
        if (!this.promises[ref]) {
            this.promises[ref] = axios.get(this.sanityBaseUrl + encodeURIComponent(this._query(ref)));
        }
        return this.promises[ref];
    };
    _query = (ref: string) => {
        return `
            *[_id == "${ref}"]{
                "overviewText": overview[0].children[0].text,
                "posterUrl": poster.asset->url
            }
        `;
    };
    purge = () => {
        this.promises = {};
    };
}

export default SanityClient;
