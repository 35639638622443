import { Elm } from './PointOfSale.elm';
import './PointOfSale/Widgets/SeatChart';
import './PointOfSale/utils/MoviePoster';
import './PointOfSale/Widgets/Input';
import assets from './assets';
import * as serviceWorker from './serviceWorker';
import * as stripeTerminalClient from './stripeTerminalClient';
import { UnleashClient } from 'unleash-proxy-client';
import * as Sentry from '@sentry/browser';
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations';
import { getTracking } from './tracking';

const settings = JSON.parse(localStorage.getItem('posSettings')) || null;

const app = Elm.PointOfSale.init({
    flags: {
        assets,
        releaseVersion: process.env.VERCEL_GIT_COMMIT_SHA || 'unknown',
        now: new Date().valueOf(),
        readerId: localStorage.getItem('readerId') || '',
        settings,
    },
});

const initTracking = (settings) => {
    const { trackEvent, traceContext } = getTracking(settings);

    if (app.ports.trackEvent) {
        app.ports.trackEvent.subscribe(function ([eventName, eventProperties]) {
            trackEvent(eventName, eventProperties);
        });
    }

    if (app.ports.traceContext && traceContext) {
        app.ports.traceContext.send(traceContext);

        if (app.ports.requestTraceContext) {
            app.ports.requestTraceContext.subscribe(() => {
                app.ports.traceContext.send(traceContext);
            });
        }
    }
};

if (settings) {
    initTracking(settings);
}

stripeTerminalClient.setDisconnectCallback(app.ports.unexpectedDisconnect.send);

app.ports.logError.subscribe((error) => {
    console.error(error);
    Sentry.captureException(new Error(error));
});
app.ports.log.subscribe(console.log);

app.ports.storeReaderId.subscribe((readerId) => {
    localStorage.setItem('readerId', readerId);
});
app.ports.clearPosSettings.subscribe(() => {
    console.log('clear pos settings');
    localStorage.removeItem('posSettings');
});
app.ports.storePosSettings_.subscribe((settings) => {
    console.log('store pos settings', settings);
    localStorage.setItem('posSettings', JSON.stringify(settings));
    initTracking(settings);
});

app.ports.getOverrideSimulatedTerminal.subscribe(() => {
    const value = Boolean(localStorage.getItem(stripeTerminalClient.doNotUseSimluatedKey) === 'true');
    app.ports.gotOverrideSimulatedTerminal.send(value);
});

app.ports.setOverrideSimulatedTerminal.subscribe((value) => {
    localStorage.setItem(stripeTerminalClient.doNotUseSimluatedKey, value);
    setTimeout(() => {
        window.location.reload();
    }, 500);
});

app.ports.getTimeZone.subscribe(() => {
    app.ports.gotTimeZone.send(Intl.DateTimeFormat().resolvedOptions().timeZone);
});

stripeTerminalClient.setLog((category, payload) => app.ports.paymentDebugLogs.send({ category, payload }));
stripeTerminalClient.setConnectionErrorCallback(app.ports.onConnectionError.send);
stripeTerminalClient.setPaymentMethodCallback(app.ports.onPaymentMethod.send);
stripeTerminalClient.setPaymentProcessedCallback(app.ports.onPaymentProcessed.send);
stripeTerminalClient.setPaymentCollectionCancelledCallback(app.ports.onPaymentCollectionCancelled.send);
stripeTerminalClient.setPaymentCapturedCallback(app.ports.onPaymentCaptured.send);

app.ports.findReaders.subscribe(function (baseUrl) {
    stripeTerminalClient.discoverReaders(baseUrl, app.ports.foundReaders.send);
});

app.ports.connectReader.subscribe(function ([baseUrl, reader]) {
    stripeTerminalClient.connectReader(reader, baseUrl, app.ports.connectionFeedback.send);
});

app.ports.collectPayment.subscribe(function ({ baseUrl, saleId, amount, clientSecret }) {
    stripeTerminalClient.collectPayment(baseUrl, clientSecret, saleId, amount);
});

app.ports.cancelCollectPayment.subscribe(function ({ baseUrl }) {
    stripeTerminalClient.cancelCollectPayment(baseUrl);
});

// unleash
const unleashEnv = (env) => {
    if (env === 'production' || env === 'development') {
        return env;
    }
    return 'staging';
};
console.log('unleashEnv -> ', unleashEnv(process.env.VERCEL_ENV));

const unleash = new UnleashClient({
    url: process.env.UNLEASH_URL,
    clientKey: process.env.UNLEASH_CLIENT_KEY,
    appName: 'point-of-sale', // hardcoded for now
    environment: unleashEnv(process.env.VERCEL_ENV),
});

app.ports.setContextForFeatureToggles.subscribe((context) => {
    console.log('setting unleash context');
    // Use `updateContext` to set Unleash context fields.
    unleash.updateContext(context);

    // Start the background polling
    unleash.start();
});

app.ports.isEnabledPort.subscribe((feature) => {
    console.log(`checkAccess for feature: ${feature}`, unleash.isEnabled(feature));

    app.ports.receiveToggleStatePort.send([feature, unleash.isEnabled(feature)]);
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Error reporting
if (process.env.VERCEL_ENV !== 'development' && process.env.VERCEL_ENV !== 'preview') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        integrations: [
            Sentry.browserTracingIntegration(),
            captureConsoleIntegration(['error']),
            httpClientIntegration(),
        ],
        release: process.env.VERCEL_GIT_COMMIT_SHA,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.VERCEL_ENV,
    });
}
