import '@webcomponents/custom-elements';
import { register } from './webcomponents';
import SanityClient from './sanityClient';

export class MoviePoster extends HTMLElement {
    constructor() {
        super();
        this.sanityClient = new SanityClient();
    }

    static get observedAttributes() {
        return ['data-ref', 'data-width', 'data-height'];
    }

    connectedCallback() {
        this.render();
    }

    get contentRef() {
        return this.getAttribute('data-ref');
    }

    get width() {
        return this.getAttribute('data-width');
    }

    get height() {
        return this.getAttribute('data-height');
    }

    get size() {
        const width = this.width;
        const height = this.height;
        const args = [];

        if (width) {
            args.push(`w=${width}`);
        }
        if (height) {
            args.push(`h=${height}`);
        }

        return '?' + args.join('&');
    }

    get widthAttribute() {
        if (this.width) {
            return `width="${this.width}"`;
        } else {
            return '';
        }
    }

    get heightAttribute() {
        if (this.height) {
            return `height="${this.height}"`;
        } else {
            return '';
        }
    }

    get posterUrl() {
        if (!this.contentRef) {
            return new Promise((resolve, _) => {
                resolve({
                    data: {
                        result: [
                            {
                                posterUrl: `https://plchldr.co/i/${this.width}x${this.height}`,
                                overviewText: 'Missing movie poster',
                            },
                        ],
                    },
                });
            });
        } else {
            return this.sanityClient.get(this.contentRef);
        }
    }

    attributeChangedCallback() {
        this.render();
    }

    render() {
        this.posterUrl.then((response) => {
            const result = response.data.result[0];
            const posterUrl = result.posterUrl;
            const overviewText = result.overviewText;

            var template = `<img src="${posterUrl}${this.size}" title="${overviewText}" ${this.widthAttribute} ${this.heightAttribute} />`;
            this.innerHTML = template;
        });
    }
}

register('movie-poster', MoviePoster);
