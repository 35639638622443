export const doNotUseSimluatedKey = 'terminal-dont-use-simulated';

const getSimulatedConfig = () => {
    if (localStorage.getItem(doNotUseSimluatedKey) === 'true' || localStorage.getItem(doNotUseSimluatedKey) === true) {
        return false;
    } else {
        return !!process.env.STRIPE_SIMULATED;
    }
};

const config = { simulated: getSimulatedConfig() };

console.log('stripe config', config);

let discoveredReaders;

const validateUrl = (url) => {
    if (url.endsWith('/') === false) {
        return url + '/';
    }

    return url;
};

// hardcode for now

function fetchConnectionTokenUrl(baseUrl) {
    return validateUrl(baseUrl) + 'connectionToken';
}

function captureUrl(baseUrl) {
    return validateUrl(baseUrl) + 'capturePaymentIntent';
}

let onDisconnect = function () {
    //placeholder, will be overridden
};
export function setDisconnectCallback(callback) {
    onDisconnect = () => {
        console.log('on disconnected');
        callback();
    };
    window.onDisconnect = onDisconnect;
}

let onPaymentMethod = function () {
    //placeholder, will be overridden
};
export function setPaymentMethodCallback(callback) {
    onPaymentMethod = callback;
}

let onPaymentProcessed = function () {
    //placeholder, will be overridden
};
export function setPaymentProcessedCallback(callback) {
    onPaymentProcessed = callback;
}

let onPaymentCollectionCancelled = function () {
    //placeholder, will be overridden
};
export function setPaymentCollectionCancelledCallback(callback) {
    onPaymentCollectionCancelled = callback;
}

let onPaymentCaptured = function () {
    //placeholder, will be overridden
};
export function setPaymentCapturedCallback(callback) {
    onPaymentCaptured = callback;
}

let onConnectionError = function () {
    //placeholder, will be overridden
};
export function setConnectionErrorCallback(callback) {
    onConnectionError = callback;
}

// override this
let log = function () {};

export let setLog = function (f) {
    log = (...args) => {
        f(...args);
        console.info(...args);
    };
};

let terminal = null;

function unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    console.log('Disconnected from reader');
    onDisconnect();
}

function fetchConnectionToken(baseUrl) {
    return async () => {
        console.log('fetching connection token');
        // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
        const response = await fetch(fetchConnectionTokenUrl(baseUrl), { method: 'POST', credentials: 'include' });
        const data = await response.json();
        return data.secret;
    };
}

// Handler for a "Discover readers" button
export async function discoverReaders(baseUrl = null, callback = () => {}) {
    console.log('discovering readers', terminal, baseUrl);
    if (terminal === null) {
        // TODO if baseUrl is defined then use it to generate the terminal
        if (baseUrl !== null) {
            console.log('creating stripe reader object');
            terminal = StripeTerminal.create({
                onFetchConnectionToken: fetchConnectionToken(baseUrl),
                onUnexpectedReaderDisconnect: unexpectedDisconnect,
            });
        }
    }
    try {
        const discoverResult = await terminal.discoverReaders(config);
        log('discoverReaders', discoverResult);
        if (discoverResult.error) {
            callback(discoverResult.error);
        } else if (discoverResult.discoveredReaders.length === 0) {
            console.log('No available readers.');
            callback([]);
        } else {
            discoveredReaders = discoverResult.discoveredReaders;
            callback(discoveredReaders);
        }
    } catch (err) {
        callback(err);
    }
}

export async function connectReader(reader, baseUrl = null, callback = () => {}) {
    console.log('connecting reader', terminal, baseUrl, reader);
    if (terminal === null) {
        // TODO if baseUrl is defined then use it to generate the terminal
        if (baseUrl !== null) {
            console.log('creating stripe reader object');
            terminal = StripeTerminal.create({
                onFetchConnectionToken: fetchConnectionToken(baseUrl),
                onUnexpectedReaderDisconnect: unexpectedDisconnect,
            });
        }
    }
    try {
        const status = terminal.getConnectionStatus();
        if (status === 'connected') {
            await terminal.disconnectReader();
        }

        const result = await terminal.connectReader(reader);
        log('connectReader', result);

        if (result.error) {
            callback(result.error);
        } else {
            callback(result);
        }
    } catch (err) {
        callback(err);
    }
}

let lastPaymentIntent = null;

export async function collectPayment(baseUrl, clientSecret, saleId, amount) {
    console.log('collecting payment', { baseUrl, saleId, clientSecret, amount });
    try {
        if (
            lastPaymentIntent !== null &&
            lastPaymentIntent.saleId === saleId &&
            lastPaymentIntent.amount === amount &&
            lastPaymentIntent.paymentIntent
        ) {
            log('Not captured intent exists', lastPaymentIntent);
            onPaymentMethod();
            onPaymentProcessed({ paymentIntentId: lastPaymentIntent.paymentIntent.id });
            await capturePayment(baseUrl, lastPaymentIntent.paymentIntent.id);

            return;
        }

        const testCardNumber = localStorage.getItem('test-card') || '4242424242424242';
        console.log('Card test number', testCardNumber);
        terminal.setSimulatorConfiguration({ testCardNumber: testCardNumber });
        const paymentMethodResult = await terminal.collectPaymentMethod(clientSecret);
        log('collectPayment > collectPaymentMethod', paymentMethodResult);

        if (paymentMethodResult.error && paymentMethodResult.error.code === 'canceled') {
            // ignore it, the cancellation handler will take care of it.
        } else if (paymentMethodResult.error) {
            onPaymentMethod({ error: paymentMethodResult.error });
        } else {
            onPaymentMethod();
            const processPaymentResult = await terminal.processPayment(paymentMethodResult.paymentIntent);
            log('processPayment > processPaymentResult', processPaymentResult);

            if (processPaymentResult.error) {
                onPaymentProcessed({ error: processPaymentResult.error });
            } else if (processPaymentResult.paymentIntent) {
                let paymentIntentId = processPaymentResult.paymentIntent.id;
                onPaymentProcessed({ paymentIntentId });
                lastPaymentIntent = {
                    saleId: saleId,
                    amount: amount,
                    paymentIntent: processPaymentResult.paymentIntent,
                };
                await capturePayment(baseUrl, paymentIntentId);
            }
        }
    } catch (err) {
        console.error('connection error', err);
        onConnectionError();
    }
}

export async function cancelCollectPayment(baseUrl) {
    console.log('cancelling payment collection');

    let result = await terminal.cancelCollectPaymentMethod();

    if (result.error) {
        console.log('error cancelling payment!: ', result.error);

        // Cancelling payment collection most likely failed because
        // the payment has already been completed... Not much we can do about that.
        onPaymentCollectionCancelled({ error: result.error });
    } else {
        console.log('successfully cancelled payment collection');

        onPaymentCollectionCancelled();
    }
}

export async function capturePayment(baseUrl, paymentIntentId) {
    try {
        console.log('capturing payment');
        const response = await fetch(captureUrl(baseUrl), {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: paymentIntentId }),
        });
        const data = await response.json();
        log('capturePayment', data);

        onPaymentCaptured();
        lastPaymentIntent = null;
    } catch (err) {
        onPaymentCaptured({ error: err });
    }
}
